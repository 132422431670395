import React, { Component } from 'react'
import { Link } from 'gatsby'

import Logo from './logo'
import { Call, ArrowDown } from './icons'

class Header extends Component {

  state = {
    active: false,
    menuActive: false,
    subActive: false
  };

  constructor(props) {
    super(props);

    this._hideOffCanvas = this._hideOffCanvas.bind(this);
    this._showOffCanvas = this._showOffCanvas.bind(this);
    this._toggleSubMenu = this._toggleSubMenu.bind(this);
  }

  _showOffCanvas() {
    this.setState({menuActive: true});
    document.querySelector('body').classList.add('menu-open');
  }

  _hideOffCanvas() {
    this.setState({menuActive: false});
    document.querySelector('body').classList.remove('menu-open');
  }

  _toggleSubMenu() {
    if ( this.state.subActive === true ) {
      this.setState({subActive: false});
      document.querySelector('.off-canvas__nav li svg').classList.remove('active');
    } else {
      this.setState({subActive: true});
      document.querySelector('.off-canvas__nav li svg').classList.add('active');
    }
  }

  render() {
    let props = {
      onClick: this._hideOffCanvas,
      activeClassName: 'active',
      onMouseEnter: this.props.func,
    }
    let subProps = {
      onClick: () => { this._hideOffCanvas(); this._toggleSubMenu() },
      activeClassName: 'active',
      onMouseEnter: this.props.func,
    }
    return (
      <>
        <header>
          <div className='header__inner'>
            <h1>
              <Link to="/"><Logo /></Link>
            </h1>
            <nav className="header__nav">
              <ul>
                <li>
                  <Link to='/managed-services'>Managed Services <ArrowDown colour="#FFFFFF" /></Link>
                  <ul className='header__submenu'>
                    <li><Link to='/managed-services#it-support' {...subProps}>IT Support</Link></li>
                    <li><Link to='/managed-services#internet-and-cloud-services' {...subProps}>Internet and Cloud Services</Link></li>
                    <li><Link to='/managed-services#voip' {...subProps}>VoIP</Link></li>
                    <li><Link to='/managed-services#project-management' {...subProps}>Project Management</Link></li>
                    <li><Link to='/managed-services#technology-advisory-services---virtual-cio' {...subProps}>Technology Advisory Services</Link></li>
                  </ul>
                </li>
                <li><Link to='/about'>About</Link></li>
                <li className="call"><a href='tel:1300688744'><Call /> 1300 688 744</a></li>
                <li><Link className="button" to='/contact'>Contact us →</Link></li>
              </ul>
            </nav>
            <button className="header__button" onClick={this._showOffCanvas}>
              <span className="lines"></span>
            </button>
          </div>
        </header>
        <div className={ this.state.menuActive ? 'off-canvas active' : 'off-canvas' }>
          <button className="hamburger active" onClick={this._hideOffCanvas}>
            <span className="lines"></span>
          </button>
          <nav className="off-canvas__nav">
            <ul>
              <li><Link to='/' {...props}>Home</Link></li>
              <li>
                <span onClick={this._toggleSubMenu}>Managed Services <ArrowDown colour="#FFFFFF" /></span>
                <ul className={ this.state.subActive ? 'off-canvas__submenu active' : 'off-canvas__submenu' }>
                  <li><Link to='/managed-services#it-support' {...subProps}>IT Support</Link></li>
                  <li><Link to='/managed-services#internet-cloud-services' {...subProps}>Internet and Cloud Services</Link></li>
                  <li><Link to='/managed-services#voip' {...subProps}>VoIP</Link></li>
                  <li><Link to='/managed-services#  project-management' {...subProps}>Project Management</Link></li>
                </ul>
              </li>
              <li><Link to='/about' {...props}>About</Link></li>
              <li><Link to='/contact' {...props}>Contact</Link></li>
            </ul>
          </nav>
          <a className="off-canvas__call" href='tel:1300688744'><Call /> 1300 688 744</a>
        </div>
      </>
    )
  }
}


export default Header
