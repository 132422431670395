import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'

import Header from '../components/header'
import Footer from '../components/footer'

import '../assets/scss/main.scss'

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      fragment WpSeo on WpPostTypeSEO {
        metaDesc
        metaKeywords
        metaRobotsNofollow
        opengraphAuthor
        opengraphDescription
        opengraphImage {
          localFile {
            publicURL
          }
        }
        opengraphModifiedTime
        opengraphPublishedTime
        opengraphPublisher
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        readingTime
        schema {
          articleType
          pageType
          raw
        }
        title
        twitterDescription
        twitterImage {
          localFile {
            publicURL
          }
        }
        twitterTitle
      }
      query SiteTitleQuery {
        wpPage(title: {eq: "Contact"}) {
          acf {
            phone
            email
            address1
            address2
          }
        }
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => {
      let cta = {
        ctaTitle: 'Outright IT is your IT partner – we fix problems the first time.',
        ctaContent: '<p>We take responsibility to fix problems, producing fast and efficient solutions. That’s the Outright IT promise.</p>',
        ctaButtonText: 'Contact us today',
        ctaButtonLink: '/contact',
      } 

      if (children.props.data) {
        let { wpPage } = children.props.data

        if (wpPage && wpPage.acf && wpPage.acf.ctaTitle) {
          cta = (
            ({ ctaTitle, ctaContent, ctaButtonText, ctaButtonLink }) => ({ ctaTitle, ctaContent, ctaButtonText, ctaButtonLink })
          )(wpPage.acf)
        }
      }

      return (
        <>
          <Header />
          <main>
            { children }
          </main>
          <Footer contact={data.wpPage.acf} cta={cta} />
        </>
      )
    }
    }
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
