import React, { Component } from 'react'
import { StaticQuery, graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import Fade from 'react-reveal/Fade'

const Image = (src) => (
  <StaticQuery
    query={graphql`
      query {
        backgroundImage: file(relativePath: { eq: "footer-background.png" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={data => <Img fluid={data.backgroundImage.childImageSharp.fluid} />}
  />
)

class Footer extends Component {
  render() {
    let { cta, contact } = this.props

    return (
      <footer className='footer'>

        <div className='footer__cta'>

          <Fade left distance='40px'>
            <span className="circle">
              <svg width="344px" height="40px" viewBox="0 0 344 40" version="1.1" xmlns="http://www.w3.org/2000/svg">
                <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g transform="translate(-83.000000, -179.000000)">
                    <path d="M84.5,199.25 L387,199.25" stroke="#40F776" strokeWidth="2" strokeLinecap="square"></path>
                    <g transform="translate(388.000000, 180.000000)">
                      <circle stroke="#40F776" strokeWidth="2" cx="19" cy="19" r="19"></circle>
                      <circle fill="#40F776" cx="19" cy="19" r="10"></circle>
                    </g>
                  </g>
                </g>
              </svg>
            </span>
          </Fade>

          <div className="copy">
            <h3>{ cta.ctaTitle }</h3>
            <div dangerouslySetInnerHTML={{__html: cta.ctaContent }} />
          </div>
          <div className="footer__cta-button">
            <Fade bottom distance='40px'>
              <Link to={cta.ctaButtonLink} className='button button--green'>{ cta.ctaButtonText } →</Link>
            </Fade>
          </div>
        </div>

        <nav className='footer__nav'>
          <div>
            <div className='footer__nav__section'>
              <h4>Managed Services</h4>
              <ul>
                <li><Link to='/managed-services#it-support'>IT Support</Link></li>
                <li><Link to='/managed-services#internet-and-cloud-services'>Internet Connectivity</Link></li>
                <li><Link to='/managed-services#voip'>VoIP</Link></li>
                <li><Link to='/managed-services#project-management'>Project Management</Link></li>
                <li><Link to='/managed-services#technology-advisory-services---virtual-cio'>Technology Advisory Services</Link></li>
              </ul>
            </div>
            <div className='footer__nav__section'>
              <h4>Head Office</h4>
              <p>
                <a href={`//maps.google.com?q=${contact.address1}`} target='_blank' rel='noopener noreferrer'>{ contact.address1 }</a>
              </p>
            </div>
            <div className='footer__nav__section'>
              <h4>Technical Office</h4>
              <p>
                <a href={`//maps.google.com?q=${contact.address2}`} target='_blank' rel='noopener noreferrer'>{ contact.address2 }</a>
              </p>
            </div>
          </div>
          <div>
            <ul className="footer__nav--small">
              <li>© Outright IT { new Date().getUTCFullYear() }. All rights reserved.</li>
              <li><Link to='/disclaimer'>Disclaimer</Link></li>
              <li><Link to='/privacy-policy'>Privacy</Link></li>
              <li><Link to='/terms-of-use'>Terms of use</Link></li>
            </ul>
            <ul className="footer__nav--small">
              <li><a href={`tel:${ contact.phone }`}>{ contact.phone }</a></li>
              <li><a href={`mailto:${ contact.email }`}>{ contact.email }</a></li>
            </ul>
          </div>
        </nav>

        <div className='footer__background'>
          <Image />
        </div>
      </footer>
    )
  }
}

export default Footer

